import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import optionsStore from '@stores/optionsStore';

interface Props {
  name: string;
  label: string;
  form: FormInstance;
  'data-test'?: string;
}

const GlobalCountyInput = observer(({ name, label, form, ...props }: Props) => {
  const [visible, setVisible] = useState(false);
  const value = optionsStore.request[name];

  function onVisibleChange(value: boolean) {
    setVisible(value);
    form.setFieldsValue({ [label]: null });
  }

  useEffect(() => {
    setVisible(!!value);
  }, [value]);

  return (
    <Row gutter={{ xs: 0 }}>
      <Col xs={24} lg={12} style={{ height: 32 }}>
        <Checkbox checked={visible} onChange={(e) => onVisibleChange(e.target.checked)}>
          {label}
        </Checkbox>
      </Col>
      <Col xs={24} lg={12}>
        {visible && (
          <BorderWrapper height={'32px'} position={'bottom'}>
            <Form.Item name={name} rules={[{ required: true, message: 'Выберите из списка' }]}>
              <RemoteSelect
                bordered={false}
                primitiveResult={true}
                autoInitDict={true}
                dataSource={dictService.globalCountries}
                valueExpr={'countryId'}
                displayExpr={'shortName'}
                data-test={props['data-test']}
              />
            </Form.Item>
          </BorderWrapper>
        )}
      </Col>
    </Row>
  );
});

export default GlobalCountyInput;
