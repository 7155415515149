import { action, makeObservable, observable } from 'mobx';

import { headerFilterSort } from '@utils/sort';

import service from './companyCatalogStore.service';

export type CompanyCatalogModel = {
  id: string;
  title: string;
  description: string;
  type: 'Default' | 'Rent' | 'Foreign' | null;
  companyId: string;
  companyCatalogId: string;
  foreignId?: string;
  sourceName?: string;
  billingProfileId?: string;
};

export interface UpdateRentResponse {
  wagonCount: number;
  containerCount: number;
}

class CompanyCatalogStore {
  @observable isBusy: boolean = false;
  @observable companyCatalogs: CompanyCatalogModel[] = [];

  constructor() {
    makeObservable(this);
  }

  @action async getCompanyCatalogs(companyId: string, search?: string): Promise<{ value: string; text: string }[]> {
    if (search) {
      return this.companyCatalogs
        .filter((n) => n.title.toLowerCase().includes(search.toLowerCase()))
        .map((n) => {
          return { value: n.id, text: (n.type == 'Rent' ? '(A) ' : '') + n.title };
        });
    }
    this.isBusy = true;
    try {
      this.companyCatalogs = await service.getCompanyCatalogs(companyId);
      const data = this.companyCatalogs
        .map((c) => ({ value: c.id, text: (c.type == 'Rent' ? '(A) ' : '') + c.title }))
        .sort(headerFilterSort);
      data.unshift({ value: null, text: '(Пустое)' });

      return data;
    } finally {
      this.isBusy = false;
    }
  }

  @action async addCompanyCatalogs(data: any, companyId: any) {
    this.isBusy = true;
    await service
      .addCompanyCatalogs(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getCompanyCatalogs(companyId);
      });
  }

  @action async saveCompanyCatalogs(data: any, companyId: string) {
    this.isBusy = true;
    await service
      .saveCompanyCatalogs(data)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getCompanyCatalogs(companyId);
      });
  }

  @action async deleteCompanyCatalogs(companyCatalogId: string, companyId: string) {
    this.isBusy = true;
    await service
      .deleteCompanyCatalogs(companyCatalogId, companyId)
      .finally(() => (this.isBusy = false))
      .then(() => {
        this.getCompanyCatalogs(companyId);
      });
  }
}

export default new CompanyCatalogStore();
