import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Row, Spin } from 'antd';
import React from 'react';

import ScrolledContainer from '@components/fw/ScrolledContainer';
import styles from '@components/fw/TagEditor/TagEditor.module.scss';

import { CompanyCatalogModel } from '@stores/companyCatalogStore';

interface CompanyCatalogListProps {
  companyCatalogs: CompanyCatalogModel[];
  onAddNew?(): void;
  onEdit?(tag: CompanyCatalogModel): void;
  onDelete?(tag: CompanyCatalogModel): void;
  isBusy?: boolean;
  height?: number;
}

const typeMapper = { Rent: 'Аренда', Default: 'Не выбран', Foreign: 'Подход', Itinerary: 'Рейсовый' };

const CompanyCatalogList: React.FC<CompanyCatalogListProps> = (props) => {
  return (
    <div>
      <Spin spinning={props.isBusy}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ padding: '10px 0' }}>
          <Col span={8}>
            <b>Название</b>
          </Col>
          <Col span={8}>
            <b>Описание</b>
          </Col>
          <Col span={4}>
            <b>Тип</b>
          </Col>
          <Col span={4}> </Col>
        </Row>
        <ScrolledContainer style={{ maxHeight: props.height ? props.height : 400, overflowX: 'hidden' }}>
          {props.companyCatalogs.map((CompanyCatalog, index) => {
            return (
              <Row key={`companyCatalog_all_${index}`}>
                <Col span={8} style={{ wordWrap: 'break-word' }}>
                  {CompanyCatalog.title}
                </Col>
                <Col span={8} style={{ wordWrap: 'break-word' }}>
                  {CompanyCatalog.description}
                </Col>
                <Col span={4}>{typeMapper[CompanyCatalog.type]}</Col>
                <Col span={4}>
                  <span className={styles.tag_list_icons_block}>
                    {!!props.onEdit && <EditOutlined className={styles.tag_list_icon} onClick={() => props.onEdit(CompanyCatalog)} />}
                    {!!props.onDelete && (
                      <Popconfirm
                        title={`Подразделение "${CompanyCatalog.title}" будет удалено. Продолжить?`}
                        onConfirm={() => props.onDelete(CompanyCatalog)}>
                        <DeleteOutlined className={styles.tag_list_icon} />
                      </Popconfirm>
                    )}
                  </span>
                </Col>
              </Row>
            );
          })}
        </ScrolledContainer>
      </Spin>

      {!!props.onAddNew && <Divider />}
      {!!props.onAddNew && (
        <div>
          <Button type="dashed" block onClick={props.onAddNew}>
            Добавить
          </Button>
        </div>
      )}
    </div>
  );
};

export default CompanyCatalogList;
